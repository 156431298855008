body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Poppins', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

main {
    margin: 0px 12px;
}

.back-btn {
    border: 1px solid lightgrey;
    margin: 2rem 2rem 0;
    font-weight: var(--font-weight-bold);
}

.add-btn-margin {
    margin-bottom: 0.5rem;
}

.btn-group-row {
    margin: 1rem 0;
}

.back-btn-row {
    min-height: 48px;
}
