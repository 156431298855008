.footer_footerContainer__PY66N {
    display: 'block';
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    align-items: center;
    box-shadow: 0px -6px 10px 5px #e4e4e4;
    background-color: white;
    height: 78px;
    padding-right: 14%;
    padding-left: 14%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.footer_footer__m9lhr {
    display: 'flex';
    justify-content: 'flex-end';
    gap: '1rem';
    align-items: center;
    padding: 2px 5%;
    height: 78px;
}

.TopNavBar_logo-wrapper__y6GyD {
    float: left;
    width: 50px;
    height: 31px;
    margin: 16px 2px 18px 18px;
}

.TopNavBar_header-title__pbswS {
    float: left;
    margin: 16px 24px 16px 2px;
    color: var(--white);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-large);
}

.TopNavBar_header-user__QTPZE {
    color: var(--white);
    font-size: var(--font-size-small);
}

.TopNavBar_headerMenuContainer__W8eOp {
    position: sticky;
    top: 0;
    z-index: 2;
    width: 100%;
}

.TopNavBar_headerMainMenuContainer__CFuWA {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--primary);
}

.TopNavBar_headerSubMenuContainer__KEH7a {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--secondary);
}

.TopNavBar_menuItems__2jZJZ {
    background-color: var(--primary) !important;
    width: 90%;
    color: var(--white) !important;
    justify-content: center;
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-bold);
    gap: 1rem;
}

.TopNavBar_subMenuItems__jAhQN {
    background-color: var(--secondary) !important;
    width: 90%;
    color: var(--white) !important;
    display: flex;
    width: 100%;
    justify-content: center;
}

.TopNavBar_headerMainMenuContainer__CFuWA .ant-menu-item.ant-menu-item-only-child {
    border-radius: var(--border-radius-round);
    padding-inline: 2.5rem;
}

.TopNavBar_headerMainMenuContainer__CFuWA .ant-menu-item.ant-menu-item-selected {
    background: var(--primary-accent);
}

.TopNavBar_headerSubMenuContainer__KEH7a .ant-menu-item.ant-menu-item-selected {
    background: none;
    font-weight: var(--font-weight-bold);
    text-decoration: underline;
}

.TopNavBar_button__gP_V9 {
    width: 100;
    margin: 16px 5px 16px 5px;
}

.loader_loader__KnqMD {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 100vh;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* We can't access CSS variables within Ant Design's themes. Make sure you also update any relevant variables in src/helpers/themeHelper.ts */

:root {
    --primary: #415ff0;
    --secondary: #5571f6;
    --brat-green: #bada55;
    --primary-accent: #68a0f5;
    --black: #1c1c1c;
    --success: #11af22;
    --danger: #c80c0c;
    --light-gray: #c1c1c1;
    --inactive: #990f02;
    --white: #fff;
    --success-message-background: #64d59f;
    --error-message-background: #df5757;
    --border-radius: 6px;
    --border-radius-round: 40px;
    --font-size-large: 1.25rem;
    --font-size-small: 0.8rem;
    --font-size-normal: 1rem;
    --font-weight-bold: 700;
    display: flex;
    flex-direction: column;
}

main {
    flex: 1 1;
}

/* latin-ext */
@font-face {
  font-family: '__Poppins_67380f';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/fe0777f1195381cb-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_67380f';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/eafabf029ad39a43-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_67380f';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/a1386beebedccca4-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_67380f';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/b957ea75a84b6ea7-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Poppins_Fallback_67380f';src: local("Arial");ascent-override: 93.62%;descent-override: 31.21%;line-gap-override: 8.92%;size-adjust: 112.16%
}.__className_67380f {font-family: '__Poppins_67380f', '__Poppins_Fallback_67380f';font-style: normal
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Poppins', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

main {
    margin: 0px 12px;
}

.back-btn {
    border: 1px solid lightgrey;
    margin: 2rem 2rem 0;
    font-weight: var(--font-weight-bold);
}

.add-btn-margin {
    margin-bottom: 0.5rem;
}

.btn-group-row {
    margin: 1rem 0;
}

.back-btn-row {
    min-height: 48px;
}

