.logo-wrapper {
    float: left;
    width: 50px;
    height: 31px;
    margin: 16px 2px 18px 18px;
}

.header-title {
    float: left;
    margin: 16px 24px 16px 2px;
    color: var(--white);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-large);
}

.header-user {
    color: var(--white);
    font-size: var(--font-size-small);
}

.headerMenuContainer {
    position: sticky;
    top: 0;
    z-index: 2;
    width: 100%;
}

.headerMainMenuContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--primary);
}

.headerSubMenuContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--secondary);
}

.menuItems {
    background-color: var(--primary) !important;
    width: 90%;
    color: var(--white) !important;
    justify-content: center;
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-bold);
    gap: 1rem;
}

.subMenuItems {
    background-color: var(--secondary) !important;
    width: 90%;
    color: var(--white) !important;
    display: flex;
    width: 100%;
    justify-content: center;
}

.headerMainMenuContainer :global(.ant-menu-item.ant-menu-item-only-child) {
    border-radius: var(--border-radius-round);
    padding-inline: 2.5rem;
}

.headerMainMenuContainer :global(.ant-menu-item.ant-menu-item-selected) {
    background: var(--primary-accent);
}

.headerSubMenuContainer :global(.ant-menu-item.ant-menu-item-selected) {
    background: none;
    font-weight: var(--font-weight-bold);
    text-decoration: underline;
}

.button {
    width: 100;
    margin: 16px 5px 16px 5px;
}
