.footerContainer {
    display: 'block';
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    align-items: center;
    box-shadow: 0px -6px 10px 5px #e4e4e4;
    background-color: white;
    height: 78px;
    padding-right: 14%;
    padding-left: 14%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.footer {
    display: 'flex';
    justify-content: 'flex-end';
    gap: '1rem';
    align-items: center;
    padding: 2px 5%;
    height: 78px;
}
