.loader {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 100vh;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
}
