/* We can't access CSS variables within Ant Design's themes. Make sure you also update any relevant variables in src/helpers/themeHelper.ts */

:root {
    --primary: #415ff0;
    --secondary: #5571f6;
    --brat-green: #bada55;
    --primary-accent: #68a0f5;
    --black: #1c1c1c;
    --success: #11af22;
    --danger: #c80c0c;
    --light-gray: #c1c1c1;
    --inactive: #990f02;
    --white: #fff;
    --success-message-background: #64d59f;
    --error-message-background: #df5757;
    --border-radius: 6px;
    --border-radius-round: 40px;
    --font-size-large: 1.25rem;
    --font-size-small: 0.8rem;
    --font-size-normal: 1rem;
    --font-weight-bold: 700;
    display: flex;
    flex-direction: column;
}

main {
    flex: 1;
}
